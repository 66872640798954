<template>
    <div class="AppListItem col ">
        <div class="col-content">
            <div class="appTitle">
                <div class="appLogo">
                    <img :src="app.iconUrl" v-bind:alt="pic">
                </div>
                <div class="appName">{{ app.name }}</div>
            </div>
            <div class="appDesc">{{ app.description }}</div>
            <button data-testid="{{ app.name.replace(' ', '_') }}"
                    class="button" type="button" @click="LoadApp()">
                {{ $t('launch') }}
            </button>
        </div>
    </div>
</template>

<script lang="ts">
    import AppRedirectService from '@/services/AppRedirectService';
    import * as Vue from 'vue';

    export default Vue.defineComponent({
        name: 'AppListItem',
        props: {
            app: Object
        },
        methods: {
            LoadApp() {
                const openInNewTab = this?.$route?.query?.sameWindow != "true";
                AppRedirectService.Go(this.app?.appRedirectUrl ?? '', this.app?.key ?? '', openInNewTab);
            }
        }
    });
</script>

<style lang="scss">
@media all and (min-width: 576px) {
    .AppListItem {
        display: flex;
        margin: 0 0 40px 0;
        align-items: center;
        max-width: 380px;
        padding: 0;
        margin-left: $standard_space / 2;
        margin-right: $standard_space / 2;

        .col-content {
            background: #fff;
            border-radius: $element_border_radius;
            padding: 10px;
            border: 2px solid #fff;
            min-width: 380px;
            max-width: 380px;
            min-height: 380px;
            max-height: 380px;
            padding-bottom: 2.0rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            a {
                font-size: 0.9rem;
                bottom: 5px;
                right: 10px;
                color: $primary_color;
                font-weight: bold;
            }

            button {
                min-width: 125px;
                max-width: 125px;
                max-height: 40px;
                min-height: 40px;
                padding: 0px;
            }

            .appLogo {
                padding-top: $standard_space * 2;
                padding-bottom: $standard_space;
                top: 15px;
                left: 10px;
                text-align: center;

                img {
                    width: 84px;
                    height: 84px;
                    border-radius: 6px;
                }
            }
        }

        .appName {
            font-weight: bold;
            margin-bottom: 5px;
            text-align: center;
            font-size: 1.25rem;
        }

        .appDesc {
            font-size: 0.9rem;
            text-align: center;
            padding-bottom: $standard_space;
            margin-left: 10px;
            margin-right: 10px;
        }

        hr {
            margin-left: $standard_space / 2;
            margin-right: $standard_space / 2;
        }

        .header-left {
            padding-left: $standard_space / 2;
            margin-bottom: 0px;
        }
    }
}

@media all and (max-width: 575px) {
    .AppListItem {
        display: flex;
        margin: 0 0 40px 0;
        align-items: center;
        max-width: 360px;
        padding-left: 0;
        padding-right: 0;

        .col-content {
            background: #fff;
            border-radius: $element_border_radius;
            padding: 10px;
            border: 2px solid #fff;
            min-width: 360px;
            max-width: 360px;
            min-height: 210px;
            max-height: 210px;
            display: flex;
            flex-direction: column;

            .appTitle {
                display: flex;
            }

            a {
                font-size: 0.9rem;
                bottom: 5px;
                right: 10px;
                color: $primary_color;
                font-weight: bold;
            }

            button {
                max-height: 40px;
                min-height: 40px;
                padding: 0px;
                align-self: center;
                width: 80%
            }

            .appLogo {
                padding-top: $standard_space / 2;
                padding-bottom: $standard_space / 2;
                margin-left: $standard_space / 2;
                top: 15px;
                left: 10px;
                text-align: center;

                img {
                    width: 48px;
                    height: 48px;
                    border-radius: 6px;
                }
            }
        }

        .appName {
            font-weight: bold;
            margin-bottom: 5px;
            text-align: left;
            font-size: 1.1rem;
            padding-top: $standard_space;
            padding-left: $standard_space / 2;
        }

        .appDesc {
            font-size: 0.9rem;
            text-align: left;
            padding-bottom: $standard_space / 2;
            margin-left: 10px;
            margin-right: 10px;
            min-height: 80px;
            max-height: 80px;
        }

        hr {
          margin-left: $standard_space / 2;
          margin-right: $standard_space / 2;
        }

        .header-left {
          padding-left: $standard_space / 2;
          margin-bottom: 0px;
        }
    }
}
</style>