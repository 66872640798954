<template>
    <div class="MoreAppListItem col ">
        <div class="col-content" @click="LoadApp()">
            <div class="appLogo">
                <img :src="app.iconUrl" v-bind:alt="pic">
            </div>
            <div class="appInfo">
                <div class="appName">{{ app.name }}</div>
                <div class="appDesc">{{ app.description }}</div>
                <a>{{ $t('learn_more') }}</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import AppRedirectService from '@/services/AppRedirectService';
import * as Vue from 'vue';

export default Vue.defineComponent({
    name: 'MoreAppListItem',
    props: {
        app: Object
    },
    methods: {
        LoadApp() {
			const openInNewTab = this?.$route?.query?.sameWindow!="true";
			AppRedirectService.Go(this.app?.appRedirectUrl ?? '', this.app?.key ?? '', openInNewTab);
        }
    }
});
</script>


<style lang="scss">
@media all and (min-width: 576px) {
    .MoreAppListItem {
        display: inline-block;
        margin: 0 0 40px 0;
        flex: 0 0 auto;
        height: 210px;
        width: 360px;
        margin-left: $standard_space / 2;
        margin-right: $standard_space / 2;

        .col-content {
            background: #fff;
            border-radius: $element_border_radius;
            padding: 10px;
            padding-left: 20%;
            border: 1px solid #e7e7e7;
            min-height: 210px;
            min-width: 360px;
            position: relative;

            a {
                font-size: 0.9rem;
                position: absolute;
                color: $primary_color;
                font-weight: bold;
                bottom: 30px;
            }

            .appInfo {
                margin-top: $standard_space;
                margin-left: 70px;
            }

            .appLogo {
                position: absolute;
                left: 10px;
                width: 20%;
                text-align: center;
                margin-top: $standard_space;
                margin-left: $standard_space;

                img {
                    width: 84px;
                    height: 84px;
                    border-radius: 6px;
                }
            }
        }

        .col-content:hover {
            border-color: $primary_color;
        }

        .col-content:hover .appName {
            color: $primary_color;
        }

        .appName {
            font-weight: bold;
            margin-bottom: 5px;
        }

        .appDesc {
            font-size: 0.9rem;
        }
    }
}

@media all and (max-width: 575px) {
    .MoreAppListItem {
        display: inline-block;
        margin: 0 0 40px 0;
        flex: 0 0 auto;
        height: 210px;
        width: 340px;
        margin-left: $standard_space / 2;
        margin-right: $standard_space / 2;
        padding-left: 0;
        padding-right: 0;

        .col-content {
            background: #fff;
            border-radius: $element_border_radius;
            padding: 10px;
            padding-left: 20%;
            border: 1px solid #e7e7e7;
            min-height: 210px;
            min-width: 340px;
            position: relative;

            a {
                font-size: 0.9rem;
                position: absolute;
                color: $primary_color;
                font-weight: bold;
                bottom: 30px;
            }

            .appInfo {
                margin-top: $standard_space;
                margin-left: 70px;
            }

            .appLogo {
                position: absolute;
                left: 10px;
                width: 20%;
                text-align: center;
                margin-top: $standard_space;
                margin-left: $standard_space;

                img {
                    width: 84px;
                    height: 84px;
                    border-radius: 6px;
                }
            }
        }

        .col-content:hover {
            border-color: $primary_color;
        }

        .col-content:hover .appName {
            color: $primary_color;
        }

        .appName {
            font-weight: bold;
            margin-bottom: 5px;
        }

        .appDesc {
            font-size: 0.9rem;
        }
    }
}
</style>